<template>
    <div class='home_main'>

        <div class="data_box">
            <div class="data_item">
                <div class="data_top">
                    <img class="bg" src="@/assets/images/home/bg1.png" alt="">
                    <div class="info">
                        <div class="img">
                            <!-- <img  src="" alt=""> -->
                        </div>
                        <div class="name">云川</div>
                    </div>
                </div>
                <div class="data_text">
                    您已经制作 <span>6</span> 分钟的视频
                </div>
                <div class="data_list">
                    <div class="item">
                        <div class="title">视频作品(个)</div>
                        <div class="num">30</div>
                    </div>
                    <div class="item">
                        <div class="title">剩余时长(分钟)</div>
                        <div class="num color4">5</div>
                    </div>
                </div>
                <div class="btn_box">
                    <el-button class="btnBgColor_red">立即充值</el-button>
                    <el-button class="btnBgColor_blue">创建视频</el-button>
                </div>
            </div>
            <!--  -->
            <div class="data_item">
                <div class="data_top">
                    <img class="bg" src="@/assets/images/home/bg1.png" alt="">
                    <div class="info">
                        <div class="img">
                            <!-- <img  src="" alt=""> -->
                        </div>
                        <div class="name">数字人直播</div>
                    </div>
                </div>
                <div class="data_text">
                    您已经创建了 <span>2</span> 个直播间
                </div>
                <div class="data_list">
                    <div class="item">
                        <div class="title">并发资源(路)</div>
                        <div class="num">12</div>
                    </div>
                    <div class="item">
                        <div class="title">剩余时间(天)</div>
                        <div class="num color4">0</div>
                    </div>
                </div>
                <div class="btn_box">
                    <el-popover popper-class="popover_corner" placement="top" width="170" trigger="hover">
                        <div class="corner_box">
                            <div class="img">
                                <img src="@/assets/images/nav/person_icon_c.png" alt="">
                            </div>
                            <div class="title">添加客服微信</div>
                            <div class="kf_img">
                                <div class="top"></div>
                                <div class="bottom"></div>
                                <div class="left"><span></span></div>
                                <div class="right"><span></span></div>
                                <img class="img_ewm" src="@/assets/images/img/service_img.png" alt="">
                            </div>
                        </div>
                        <el-button slot="reference" class="btnBgColor_blue_empty">联系客服</el-button>
                    </el-popover>
                    <el-button class="btnBgColor_blue" @click="toLive">开始直播</el-button>
                </div>
            </div>
            <!--  -->
            <div class="data_item">
                <div class="data_top">
                    <img class="bg" src="@/assets/images/home/bg1.png" alt="">
                    <div class="info">
                        <div class="img">
                            <!-- <img  src="" alt=""> -->
                        </div>
                        <div class="name">DUIX</div>
                    </div>
                </div>
                <div class="data_text">
                    您已经创建了 <span>3</span> 个对话
                </div>
                <div class="data_list">
                    <div class="item">
                        <div class="title">并发资源(路)</div>
                        <div class="num">1</div>
                    </div>
                    <div class="item">
                        <div class="title">剩余时间(天)</div>
                        <div class="num color4">1</div>
                    </div>
                </div>
                <div class="btn_box">
                    <el-button class="btnBgColor_blue" @click="toDuix">DEMO体验</el-button>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="equity">
            <div class="equity_top">
                <div class="equity_tit">
                    <div class="dot"></div>
                    <span>基础权益</span>
                </div>
                <div class="num_box">
                    模特定制次数：<span class="num">18</span> 次
                    <span class="btn">立即定制</span>
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>

            <div class="card_box">
                <div class="card_one">
                    <div class="card_left">
                        <div class="card_tit">
                            <img src="@/assets/images/home/icon1.png" alt="">
                            <span>数字人形象 (个)</span>
                        </div>
                        <div class="number">207</div>
                    </div>
                    <div class="line"></div>
                    <div class="card_right">
                        <charts :pieList="pieList1" ids="one"></charts>
                    </div>
                </div>
                <div class="card_three">
                    <div class="card_tit">
                        <img src="@/assets/images/home/icon2.png" alt="">
                        <span>TTS模型 (个)</span>
                    </div>
                    <div class="number">207</div>
                </div>
                <div class="card_three">
                    <div class="card_tit">
                        <img src="@/assets/images/home/icon3.png" alt="">
                        <span>视频模版 (个)</span>
                    </div>
                    <div class="number">12</div>
                </div>
            </div>

            <div class="equity_top" style="margin-top:20px;">
                <div class="equity_tit">
                    <div class="dot"></div>
                    <span>我的资产</span>
                </div>
            </div>

            <div class="card_box">
                <div class="card_one">
                    <div class="card_left">
                        <div class="card_tit">
                            <img src="@/assets/images/home/icon1.png" alt="">
                            <span>数字人形象 (个)</span>
                        </div>
                        <div class="number">0</div>
                    </div>
                    <div class="line"></div>
                    <div class="card_right">
                        <charts :pieList="pieList2" ids="two"></charts>
                    </div>
                    <div class="line"></div>
                    <div class="card_right">
                        <charts :pieList="pieList3" ids="three"></charts>
                    </div>
                </div>
                <div class="card_three">
                    <div class="card_tit">
                        <img src="@/assets/images/home/icon2.png" alt="">
                        <span>TTS模型 (个)</span>
                    </div>
                    <div class="number">0</div>
                </div>
            </div>
        </div>
        <point-out ref='onloadTip' dialogTitle="温馨提示" dialogWidth='380px' type="onload" :iconShow='true' iconColor='#FF7432' borderRadius='10px' :affirmText="'立即下载'" :cancelShow='false' :affirmShow='true' @onload="onload">
            <div class="zc_dialog_tip_content">
                <div class="text">请先下载PC客户端，完成安装后再体验，客户端目前仅支持windows版本哦～</div>
            </div>
        </point-out>

    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
import Charts from './components/charts.vue'
export default {
    components: {
        Charts,
        PointOut
    },
    data() {
        return {
            dataList: [],
            pieList1: [
                {
                    name: '视频',
                    color: '#2E4BF2',
                    value: 232,
                },
                {
                    name: '直播',
                    color: '#FF5F5F',
                    value: 185,
                },
                {
                    name: 'DUIX',
                    color: '#32DFFF',
                    value: 164,
                },
            ],
            pieList2: [
                {
                    name: '视频',
                    color: '#2E4BF2',
                    value: 0,
                },
                {
                    name: '直播',
                    color: '#FF5F5F',
                    value: 0,
                },
                {
                    name: 'DUIX',
                    color: '#32DFFF',
                    value: 0,
                },
            ],
            pieList3: [
                {
                    name: '定制',
                    color: '#C159FF',
                    value: 0,
                },
                {
                    name: '购买',
                    color: '#FFA83A',
                    value: 0,
                },
            ],
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 下载客户端
        onload() {

        },
        toLive() {
            this.$refs.onloadTip.openDialogBtn()
        },
        // 体验duix
        toDuix() {
            let routeData = this.$router.resolve({ path: '/session' });
            window.open(routeData.href, '_blank');
        },
    },
}
</script>

<style lang='scss' scoped>
.home_main {
    width: 100%;
    min-width: 1400px;
    .data_box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .data_item {
            width: 32%;
            height: 394px;
            background: #ffffff;
            border-radius: 20px;
            .data_top {
                width: 100%;
                height: 110px;
                position: relative;
                .bg {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                }
                .info {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    align-items: center;
                    padding: 30px 0 0 30px;
                    .img {
                        width: 40px;
                        height: 40px;
                        border-radius: 6px;
                        background: #ffffff;
                    }
                    .name {
                        padding-left: 16px;
                        font-size: 24px;
                        font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
                        font-weight: normal;
                        color: #ffffff;
                        line-height: 33px;
                    }
                }
            }
            .data_text {
                font-size: 14px;
                color: #333333;
                padding-top: 10px;
                padding-left: 30px;
                span {
                    color: #000000;
                    font-size: 36px;
                }
            }
            .data_list {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 20px 30px 30px 30px;
                .item {
                    width: 48%;
                    padding: 20px 30px;
                    background: url(~@/assets/images/home/bg4.png) no-repeat
                        100% / cover;
                    .title {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                    .num {
                        font-size: 26px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        padding-top: 10px;
                    }
                    .color4 {
                        color: $redColor4;
                    }
                }
            }
            .btn_box {
                display: flex;
                justify-content: flex-end;
                margin-right: 30px;
                .btnBgColor_blue {
                    margin-left: 10px;
                }
            }
        }
    }
    .equity {
        width: 100%;
        padding: 30px;
        background: #ffffff;
        border-radius: 20px;
        margin-top: 20px;
        .equity_top {
            display: flex;
            justify-content: space-between;
            .equity_tit {
                display: flex;
                align-items: center;
                .dot {
                    width: 5px;
                    height: 18px;
                    background: #2e4bf2;
                    border-radius: 3px;
                }
                span {
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 18px;
                    padding-left: 10px;
                }
            }
            .num_box {
                width: 246px;
                height: 38px;
                background: #f2f7ff;
                border-radius: 22px;
                font-size: 14px;
                color: #666666;
                line-height: 38px;
                text-align: center;
                .num {
                    font-size: 18px;
                    font-weight: 500;
                }
                .btn {
                    color: #2e4bf2;
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }
        .card_box {
            width: 100%;
            height: 140px;
            margin-top: 20px;
            display: flex;
            .card_one {
                height: 140px;
                background: #f2f7ff;
                border-radius: 10px;
                display: flex;
                align-items: center;
                margin-right: 30px;
                padding-right: 20px;
                .card_left {
                    width: 212px;
                    padding-left: 30px;
                    .card_tit {
                        display: flex;
                        font-size: 16px;
                        color: #333333;

                        img {
                            width: 22px;
                            height: 22px;
                            margin-right: 8px;
                        }
                    }
                    .number {
                        font-size: 30px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: $redColor4;
                        line-height: 42px;
                        padding-top: 16px;
                    }
                }
                .card_right {
                    padding-top: 10px;
                    width: 260px;
                    margin-right: 20px;
                    height: 100%;
                }
                .line {
                    width: 1px;
                    height: 67px;
                    background: #d9d9d9;
                }
            }
            .card_three {
                width: 360px;
                height: 140px;
                background: #f2f7ff url(~@/assets/images/home/bg5.png) no-repeat
                    100% / cover;
                border-radius: 10px;
                padding: 30px;
                margin-right: 30px;
                .card_tit {
                    display: flex;
                    font-size: 16px;
                    color: #333333;

                    img {
                        width: 22px;
                        height: 22px;
                        margin-right: 8px;
                    }
                }
                .number {
                    font-size: 30px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 42px;
                    padding-top: 16px;
                }
            }
        }
    }
}
</style>
